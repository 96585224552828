.road-line {
  animation: moveRoad 2s linear infinite;
}

@keyframes moveRoad {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 60;
  }
}

.reveal-text {
  transform-style: preserve-3d;
  perspective: 1000px;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@keyframes aurora {
  0% {
    transform: translate(0, 0) rotate(0);
  }
  50% {
    transform: translate(-10px, 10px) rotate(5deg);
  }
  100% {
    transform: translate(0, 0) rotate(0);
  }
}
